import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export const userSliceName = 'user';

export type UserState =
    | {
          userName: string | null;
          accessToken: null;
          customerId: null;
          email: null;
          phone: null;
          shouldDisplayLogoutPopup: boolean;
      }
    | {
          userName: string;
          accessToken: string;
          customerId: number;
          email: string;
          phone: string;
          shouldDisplayLogoutPopup: boolean;
      };

const initialState: UserState = {
    userName: null,
    accessToken: null,
    customerId: null,
    email: null,
    phone: null,
    shouldDisplayLogoutPopup: false,
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>, string>({
    name: userSliceName,
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<UserState | { userName: string }>) => ({
            ...state,
            ...action.payload,
        }),
        updateLogoutPopupDisplay: (state, action: PayloadAction<boolean>) => ({
            ...state,
            shouldDisplayLogoutPopup: action.payload,
        }),
        resetUser: (_state, _action: PayloadAction) => ({ ...initialState }),
    },
});

export const getUser = (state: RootState) => state.user;
export const getUserName = (state: RootState) => getUser(state).userName;
export const getAccessToken = (state: RootState) => getUser(state).accessToken;
export const getCustomerId = (state: RootState) => getUser(state).customerId;
export const getEmail = (state: RootState) => getUser(state).email;
export const getPhone = (state: RootState) => getUser(state).phone;
export const getShouldDisplayLogoutPopup = (state: RootState) =>
    getUser(state).shouldDisplayLogoutPopup;

export const { updateUser, updateLogoutPopupDisplay, resetUser } = userSlice.actions;
