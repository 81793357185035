import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ConsumptionModeType } from '@innovorder/order_detail';
import { RootState } from '../store';

export const appSliceName = 'app';

export type AppState = {
    restaurantId: number | null;
    restaurantName: string | null;
    restaurantTags: { value: string }[] | null;

    currentMenuId: number | null;
    currentOrderId: string | null;
    currentConsumptionModeType: ConsumptionModeType | null;
    currentFamilyFilter: number | null;
    debouncingQuantity: Record<string, number> | null;

    tableId: number | null;
    isFoodCourt: boolean | null;
    crossSellingAlreadyTriggered: boolean;

    isIncognitoMode: boolean;
    isDisconnected: boolean;

    anonymousId: string | null;
    idempotencyKey: string;
    isPaymentConfirmed: boolean;
};

const initialState: AppState = {
    restaurantId: null,
    restaurantName: null,
    restaurantTags: null,

    currentMenuId: null,
    currentOrderId: null,
    currentConsumptionModeType: null,
    currentFamilyFilter: null,
    debouncingQuantity: null,

    tableId: null,
    isFoodCourt: null,
    crossSellingAlreadyTriggered: false,

    isIncognitoMode: false,
    isDisconnected: false,

    anonymousId: null,
    idempotencyKey: '',
    isPaymentConfirmed: false,
};

export const appSlice = createSlice<AppState, SliceCaseReducers<AppState>, string>({
    name: appSliceName,
    initialState,
    reducers: {
        updateAppState: (state, action: PayloadAction<Partial<AppState>>) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const getAppState = (state: RootState): AppState => state.app;
export const getByAccessor = (accessorTitle?: 'restaurantName') => (state: RootState) =>
    accessorTitle ? state.app[accessorTitle] : null;
export const getTableId = (state: RootState) => getAppState(state).tableId;
export const getRestaurantId = (state: RootState) => getAppState(state).restaurantId;
export const getRestaurantName = (state: RootState) => getAppState(state).restaurantName;
export const getRestaurantTags = (state: RootState) => getAppState(state).restaurantTags;
export const getCurrentMenuId = (state: RootState) => getAppState(state).currentMenuId;
export const getCurrentConsumptionModeType = (state: RootState) =>
    getAppState(state).currentConsumptionModeType;
export const getIsFoodCourt = (state: RootState) => getAppState(state).isFoodCourt;
export const getCurrentOrderId = (state: RootState) => getAppState(state).currentOrderId;
export const getCrossSellingAlreadyTriggered = (state: RootState) =>
    getAppState(state).crossSellingAlreadyTriggered;
export const getCurrentFamilyFilter = (state: RootState) => getAppState(state).currentFamilyFilter;
export const getDebouncingQuantity = (state: RootState) => getAppState(state).debouncingQuantity;
export const getIsIncognitoMode = (state: RootState) => getAppState(state).isIncognitoMode;
export const getIsDisconnected = (state: RootState) => getAppState(state).isDisconnected;
export const getAnonymousId = (state: RootState) => getAppState(state).anonymousId;
export const getIdempotencyKey = (state: RootState) => getAppState(state).idempotencyKey;
export const getIsPaymentConfirmed = (state: RootState) => getAppState(state).isPaymentConfirmed;

export const { updateAppState } = appSlice.actions;
