"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyDiscounts = void 0;
var discount_types_1 = require("../../types/discount.types");
var grant_strategy_1 = require("../scopes/grant-strategy");
var grant_tags_1 = require("../scopes/grant-tags");
var compute_tax_rate_1 = require("../tax-rate/compute-tax-rate");
var compute_total_price_from_items_1 = require("../utils-computing/compute-total-price-from-items");
// eslint-disable-next-line import/no-cycle
var compute_discounted_amount_1 = require("./compute-discounted-amount");
var buildNewItemTotalPriceNotRounded = function (item, discountType, discountedAmountInclTax) {
    var newPriceInclTaxWithDiscount = item.totalPriceNotRounded.totalInclTaxWithDiscount.minus(discountedAmountInclTax);
    var newPriceExclTaxWithDiscount = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(newPriceInclTaxWithDiscount, item.taxRate);
    var newItemTotalPriceNotRounded = __assign(__assign({}, item.totalPriceNotRounded), { totalInclTaxWithDiscount: newPriceInclTaxWithDiscount, totalExclTaxWithDiscount: newPriceExclTaxWithDiscount });
    if (discount_types_1.RemiseTypes.includes(discountType)) {
        var newPriceInclTaxWithRemise = item.totalPriceNotRounded.totalInclTaxWithRemise.minus(discountedAmountInclTax);
        var newPriceExclTaxWithRemise = (0, compute_tax_rate_1.computeExclTaxFromInclTax)(newPriceInclTaxWithRemise, item.taxRate);
        newItemTotalPriceNotRounded = __assign(__assign({}, newItemTotalPriceNotRounded), { totalInclTaxWithRemise: newPriceInclTaxWithRemise, totalExclTaxWithRemise: newPriceExclTaxWithRemise });
    }
    return newItemTotalPriceNotRounded;
};
var applyDiscountOnItems = function (items, discount, totalPriceWithDiscount) {
    var usedAmountNotRoundedInclTax = discount.usedAmountNotRoundedInclTax, usedAmountNotRoundedExclTax = discount.usedAmountNotRoundedExclTax;
    var discountables = discount.discountables;
    var itemsUpdated = items.map(function (item) {
        if (!(0, grant_tags_1.isItemInDiscountScope)(item, discount)) {
            return item;
        }
        var _a = (0, compute_discounted_amount_1.computeDiscountedAmount)({
            itemPriceNotRounded: item.totalPriceNotRounded,
            taxRate: item.taxRate,
            discount: discount,
            totalPriceWithDiscount: totalPriceWithDiscount,
        }), discountedAmountInclTax = _a.discountedAmountInclTax, discountedAmountExclTax = _a.discountedAmountExclTax;
        usedAmountNotRoundedInclTax = usedAmountNotRoundedInclTax.plus(discountedAmountInclTax);
        usedAmountNotRoundedExclTax = usedAmountNotRoundedExclTax.plus(discountedAmountExclTax);
        discountables.push({
            id: item.id,
            taxRate: item.taxRate,
            discountedAmountInclTax: discountedAmountInclTax,
            discountedAmountExclTax: discountedAmountExclTax,
        });
        var newItemTotalPriceNotRounded = buildNewItemTotalPriceNotRounded(item, discount.type, discountedAmountInclTax);
        return __assign(__assign({}, item), { totalPriceNotRounded: newItemTotalPriceNotRounded });
    });
    var discountUpdated = __assign(__assign({}, discount), { usedAmountNotRoundedInclTax: usedAmountNotRoundedInclTax, usedAmountNotRoundedExclTax: usedAmountNotRoundedExclTax, discountables: discountables });
    return { itemsUpdated: itemsUpdated, discountUpdated: discountUpdated };
};
var isPriceBeyondThreshold = function (discount, amount) {
    return amount.decimalPlaces(10).comparedTo(discount.threshold) >= 0;
};
var applyDiscounts = function (items, discounts) {
    discounts = discounts.map(function (discount) {
        // evaluate if the discount strategy can be applied
        if (!(0, grant_strategy_1.canApplyGrantStrategy)(items, discount)) {
            return discount;
        }
        // evaluate for every discounts what items are in the scope
        var scopedItems = items.filter(function (item) { return (0, grant_tags_1.isItemInDiscountScope)(item, discount); });
        // compute the total price of those scope items only
        var scopedTotalPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(scopedItems);
        // apply the discount on the scoped items
        var updatedProductsAndDiscount = applyDiscountOnItems(items, discount, scopedTotalPrice.totalInclTaxWithDiscount);
        // if the new total value of the scoped items is below the grant threshold, we don't update the items value
        var newScopedTotalPrice = (0, compute_total_price_from_items_1.computeTotalPriceFromItems)(updatedProductsAndDiscount.itemsUpdated);
        if (discount.type === discount_types_1.DISCOUNT_TYPE.GRANT &&
            !isPriceBeyondThreshold(discount, newScopedTotalPrice.totalInclTaxWithDiscount)) {
            return discount;
        }
        // update the new current prices
        items = updatedProductsAndDiscount.itemsUpdated;
        discount = updatedProductsAndDiscount.discountUpdated;
        return discount;
    });
    return {
        products: items.filter(function (item) { return item.discountableType === discount_types_1.DISCOUNTABLE_TYPE.PRODUCT; }),
        fees: items.filter(function (item) { return item.discountableType === discount_types_1.DISCOUNTABLE_TYPE.FEE; }),
        discounts: discounts,
    };
};
exports.applyDiscounts = applyDiscounts;
